import React, { useEffect, useContext } from "react";
import cryptoRandomString from "crypto-random-string";
import { useCookies } from "react-cookie";
import { DeviceIdContext, SessionIdContext } from "../lib/contexts";

const sha256 = async (plain: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
  return hashHex;
};

const LoginContainer = () => {
  const [, setCookie] = useCookies();
  const deviceId = useContext(DeviceIdContext);
  const sessionId = useContext(SessionIdContext);
  useEffect(() => {
    async function initAuthentication() {
      const authId = cryptoRandomString({ length: 60 });
      const authNonce = cryptoRandomString({ length: 40 });
      const codeVerifier = cryptoRandomString({ length: 43 });
      const codeChallenge = await sha256(codeVerifier);
      setCookie(
        `auth.${authId}`,
        encodeURIComponent(
          JSON.stringify({
            nonce: authNonce,
            code_verifier: codeVerifier
          })
        ),
        {
          path: "/",
          expires: new Date(Date.now() + 86400000) // 1 day
        }
      );

      if (process.browser) {
        window.location.href = `${
          process.env.graphQLUrl
        }/authorise?deviceId=${deviceId}&sessionId=${sessionId}&clientId=${encodeURIComponent(
          process.env.clientId
        )}&state=${encodeURIComponent(authId)}&nonce=${encodeURIComponent(
          authNonce
        )}&codeChallenge=${encodeURIComponent(
          codeChallenge
        )}&codeChallengeMethod=S256&redirectUri=${encodeURIComponent(
          `${process.env.websiteUrl}/login/callback`
        )}`;
      }
    }

    if (deviceId && sessionId) {
      initAuthentication();
    }
  }, [setCookie, deviceId, sessionId]);
  return <div />;
};

export default LoginContainer;
