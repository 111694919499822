import React from "react";
import LoginContainer from "../../containers/login";
import { PageComponent } from "../_app";

const LoginPage: PageComponent = () => {
  return <LoginContainer />;
};

LoginPage.title = "Waggel - Login";
LoginPage.redirectToLoginIfNotAuthenticated = false;
LoginPage.header = false;

export default LoginPage;
